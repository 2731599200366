import {get,post} from "@/utils/axios"
//获取商品套餐详情
const getProductPackages = p => get('api-common/goods/getProductPackages', p);

//获取商品套餐详情
const getPackagesSpecList = p => get('api-common/goods/getPackagesSpecList', p);

//获取商品套餐详情
const getBillingModeList = p => post('api-common/goods/getBillingModeList', p);

//获取商品详情
const getGoodDescription = p => get('api-common/goods/getGoodsDescription', p)

export default {
    getProductPackages,
    getPackagesSpecList,
    getBillingModeList,
    getGoodDescription
}