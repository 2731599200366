<template>
  <el-row>
    <el-col class="goods-back-bg" :xs="0">
      <el-col class="bg-content" :md="15">
        {{ goodsInfo.saasProductName }}
      </el-col>
    </el-col>
    <el-col class="goods-info">
      <el-col class="goods-specs goods" :sm="0" :span="24">
        <el-col class="img" :span="24" :sm="0">
          <img :src="goodsInfo.saasProductLogo" style="width: 100%;height: 100%">
        </el-col>
        <el-col class="img" :span="9" :xs="0">
          <img :src="goodsInfo.saasProductLogo" style="width: 100%;height: 100%">
        </el-col>
        <!-- 立即购买悬浮 -->
        <el-col class="down-mobile" :sm="0" :span="24">
          <el-col style="background: #FFFFFF;  height: 52px;" :span="24">
            <el-col class="down-count" :span="2">
              总计
            </el-col>
            <el-col class="down-￥" :span="1">
              ￥
            </el-col>
            <el-col class="down-buy-price" :span="3">
              {{ price }}
            </el-col>
            <el-col style="margin: 9px -29px -9px 162px;" :span="1">
              <el-button class="down-buy-button" @click="drawer = true" >立即购买</el-button>
            </el-col>
          </el-col>
        </el-col>
        <!-- 立即购买卡片 -->
        <el-col :span="24" :sm="0">
          <el-col style="background: #3764D7;  height: 56px; margin: 8px 8px 0px 8px; float: unset;" :span="23">
            <el-col class="buy-now" :span="4">
              立即购买
            </el-col>
            <el-col class="￥" :span="2">
              ￥
            </el-col>
            <el-col class="buy-price" :span="10">
              {{ price }}
            </el-col>
          </el-col>
          <el-col :span="23" style="border: 1px dashed #0000007a; height: 62px; margin: 0px 8px 0px 8px">
            <el-col class="card" :span="12">
              伊登SaaS商城
            </el-col>
            <el-col :span="12" class="productCard">
              {{ goodsInfo.saasProductName }}
            </el-col>
          </el-col>
        </el-col>
        <el-col class="specs" :span="11" :xs="0">
          <el-col class="product-name" :span="24">
            {{ goodsInfo.saasProductName }}
          </el-col>
          <el-col class="specs-name product-item" :span="24">
            <el-col class="item-title" :span="24">
              规格名称：
            </el-col>
            <el-col class="item-list" :span="24">
              <el-col :span="6" :title="item.productSpecsName" class="item text-overflow"
                      :class="{active: specsIndex == index}" v-for="(item, index) in productSpecsList" :key="index"
                      @click.native="chooseProductSpecs(index)">
                {{ item.productSpecsName }}
              </el-col>
              <el-col :span="20" v-if="ifOther">
                <div class="other-spaces">
                  <div style="margin-bottom: 10px;">
                    用户：
                    <el-input-number v-model="inputUserNum" :min="basicUser" :step="basicUser" step-strictly
                                     @change="calculateSpacesPrice"></el-input-number>
                  </div>
                  <div>
                    容量：
                    <el-input-number v-model="inputCapacity" :min="basicCapacity" :step="basicCapacity" step-strictly
                                     @change="calculateSpacesPrice"></el-input-number>
                    <el-select style="width: 60px;margin-left: 5px" v-model="capacityUnit" @change="inputUnitChange">
                      <el-option label="G" value="G">G</el-option>
                      <el-option label="T" value="T">T</el-option>
                    </el-select>
                  </div>
                </div>
              </el-col>
            </el-col>
          </el-col>
          <el-col class="pay-type product-item" :span="24">
            <el-col class="item-title" :xl="24">
              购买方式：
            </el-col>
            <el-col class="item-list" :xl="24">
              <el-col :span="6" class="item" :class="{active: purchaseIndex == index}"
                      v-for="(item, index) in productPurchaseList" :key="index"
                      @click.native="choosePurchaseType(index)">
                <!--                按月购买-->
                <span v-if="item.billingMode == 1">按月购买</span>
                <span v-if="item.billingMode == 2">按年购买</span>
                <span v-if="item.billingMode == 3">试用</span>
              </el-col>
            </el-col>
          </el-col>
          <el-col class="duration product-item">
            <el-col class="item-title" :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
              购买时长：
            </el-col>
            <el-col class="choose-duration" :xs="12" :sm="15" :md="12" :lg="10" :xl="6">
              <el-input-number v-model="duration" :min="1" :max="durationMax"
                               @change="durationChange"></el-input-number>
              <el-col class="duration-type" :span="2">({{ unit }})</el-col>
            </el-col>
          </el-col>
          <el-col class="price product-item">
            <el-col class="item-title" :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
              总&#12288;&#12288;计：
            </el-col>
            <el-col class="item-price" :xs="12" :sm="8" :md="7" :lg="6" :xl="5">
              <el-col class="cur-price" :span="24">
                ¥{{ price }}
              </el-col>
              <!--              <el-col class="aft-price" :span="6">-->
              <!--                已优惠12.00元-->
              <!--              </el-col>-->
            </el-col>
          </el-col>
          <el-col class="pay-btn">
            <el-button @click="purchase">立即购买</el-button>
          </el-col>
        </el-col>
      </el-col>
      <el-col class="goods-specs goods" :xs="0" :span="15">
        <el-col class="img" :span="9" :xs="0">
          <img src="../../img/goods/test.png" style="width: 100%;height: 100%">
        </el-col>
        <el-col class="specs" :span="11" :xs="0">
          <el-col class="product-name" :span="24">
            {{ goodsInfo.saasProductName }}
          </el-col>
          <el-col class="specs-name product-item" :span="24">
            <el-col class="item-title" :span="24">
              规格名称：
            </el-col>
            <el-col class="item-list" :span="24">
              <el-col :span="6" :title="item.productSpecsName" class="item text-overflow"
                      :class="{active: specsIndex == index}" v-for="(item, index) in productSpecsList" :key="index"
                      @click.native="chooseProductSpecs(index)">
                {{ item.productSpecsName }}
              </el-col>
              <el-col :span="20" v-if="ifOther">
                <div class="other-spaces">
                  <div style="margin-bottom: 10px;">
                    用户：
                    <el-input-number v-model="inputUserNum" :min="basicUser" :step="basicUser" step-strictly
                                     @change="calculateSpacesPrice"></el-input-number>
                  </div>
                  <div>
                    容量：
                    <el-input-number v-model="inputCapacity" :min="basicCapacity" :step="basicCapacity" step-strictly
                                     @change="calculateSpacesPrice"></el-input-number>
                    <el-select style="width: 60px;margin-left: 5px" v-model="capacityUnit" @change="inputUnitChange">
                      <el-option label="G" value="G">G</el-option>
                      <el-option label="T" value="T">T</el-option>
                    </el-select>
                  </div>
                </div>
              </el-col>
            </el-col>
          </el-col>
          <el-col class="pay-type product-item" :span="24">
            <el-col class="item-title" :xl="24">
              购买方式：
            </el-col>
            <el-col class="item-list" :xl="24">
              <el-col :span="6" class="item" :class="{active: purchaseIndex == index}"
                      v-for="(item, index) in productPurchaseList" :key="index"
                      @click.native="choosePurchaseType(index)">
                <!--                按月购买-->
                <span v-if="item.billingMode == 1">按月购买</span>
                <span v-if="item.billingMode == 2">按年购买</span>
                <span v-if="item.billingMode == 3">试用</span>
              </el-col>
            </el-col>
          </el-col>
          <el-col class="duration product-item">
            <el-col class="item-title" :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
              购买时长：
            </el-col>
            <el-col class="choose-duration" :xs="12" :sm="15" :md="12" :lg="10" :xl="6">
              <el-input-number v-model="duration" :min="1" :max="durationMax"
                               @change="durationChange"></el-input-number>
              <el-col class="duration-type" :span="2">({{ unit }})</el-col>
            </el-col>
          </el-col>
          <el-col class="price product-item">
            <el-col class="item-title" :xs="12" :sm="8" :md="6" :lg="5" :xl="4">
              总&#12288;&#12288;计：
            </el-col>
            <el-col class="item-price" :xs="12" :sm="8" :md="7" :lg="6" :xl="5">
              <el-col class="cur-price" :span="24">
                ¥{{ price }}
              </el-col>
              <!--              <el-col class="aft-price" :span="6">-->
              <!--                已优惠12.00元-->
              <!--              </el-col>-->
            </el-col>
          </el-col>
          <el-col class="pay-btn">
            <el-button @click="purchase">立即购买</el-button>
          </el-col>
        </el-col>
      </el-col>
      <el-col class="dividing-line top" :span="15" :xs="0"></el-col>
      <el-col class="product-introduce" :span="15" :xs="0">
        <el-col class="line"></el-col>
        <el-col class="title" :span="10">产品介绍</el-col>
      </el-col>
      <el-col class="dividing-line bottom" :span="15" :xs="0"></el-col>
      <el-col class="mobile-title" :span="10" :sm="0">产品介绍</el-col>
      <el-col class="introduce-detail" :xs="0" :span="15">
        <el-col class="detail">
          <el-col>
            <strong>商品亮点：</strong>
            轻松向客户、公司内部员工分享文件，
            轻松向客户、公司内部员工分享文件，
            区分个人文档和部门文档，受到系统特定的权限控制，高效地团队协作，
            记录用户操作日志，系统审计有迹可循，
          </el-col>
          <el-col>
            <strong>商品说明：</strong>
            本商品为SAAS标准产品，即买即用，不占用客户服务器资源。如需私有化部署或定制需求请联系我们，服务热线：400-830-0095。
          </el-col>
          <el-col><strong>商品介绍：</strong></el-col>
          <el-col>
            <ul>
              <li>伊登云文档管理系统是企业级的文档协同管理平台，专注为客户打造一个集中存储、安全、灵活高效的文档协同平台，对文档进行全生命周期管理与数据分析，实现内容创造价值，知识驱动未来。</li>
              <li>
                文档管理系统区分个人文档和部门文档，文档数据的上传、预览、删除、共享、分享、授权受到系统特定的权限控制，打破传统文档的管理方式，不受时间和空间的限制，形成高效地团队协作平台，操作动态数据可查，让企业内部协同办公更加安全放心。
              </li>
              <li>支持常用的Office文档（Word，Excel，PPT）、PDF、图片文件的预览及常见的视频和音频文件在线播放。</li>
              <li>用户个人文档下的文件或文件夹，通过共享方式授权给内部其他用户访问，授权方式包含新建、上传、下载、查看，既保证文件传输的高效快捷，又能保证文件的安全。</li>
              <li>高级搜索：系统支持快速搜索、标签搜索、标题搜索、文档内容搜索、创建人搜索等多种搜索形式，帮助用户快速查找定位资料。</li>
              <li>外接分享：用户可以对系统内的文件生成外链，系统外用户可以通过外链下载文件进行访问；可以对外链文件进行密码设置，访问次数设置，过期时间设置。</li>
              <li>文件标签：标签功能是一个灵活的文件分类方式，系统中所有对文件有编辑权限的用户都可以为文件添加标签，用户根据自己的需要为文件添加标签，方便用户分类查找与检索文件。</li>
              <li>文档比对：针对两份PDF文件，可进行内容在线对比，用颜色标识差异的内容，不同内容用颜色突出显示，一目了然。</li>
              <li>权限管理：6大模块细分55项权限设置。既支持对单个文件进行授权，也支持对单个用户/角色进行授权。</li>
              <li>流程管理：灵活自定义多种审批流程，系统支持外发分享审批。资料分享给外部用户需要系统内部审核机制通过后才会生成分享链接，确保资料安全。可按资料的机密等级，划定不同的审批权限。</li>
              <li>日志审计：系统可自动记录用户行为，并自动生成登录日志、组织操作日志、文档操作日志；安全管理员可通过审计日志监控系统使用情况，确保整体运营安全。</li>
              <li>回收站：针对用户误删除，或者恶意删除的文件，系统支持回收站管理，进行二次保护。对回收站内的文件，由文件上传人员进行处理，是否彻底删除或者恢复文件。</li>
            </ul>
          </el-col>
          <el-col><strong>服务时间：</strong>工作日9:00-18：00</el-col>
          <el-col><strong>服务热线：</strong>400-830-0095</el-col>
          <el-col><strong>售后服务内容：</strong>产品支持及使用问题跟踪</el-col>
          <el-col><strong>邮箱：</strong>EdenService@edensoft.com.cn</el-col>

          <!--          <el-col>提醒功能：@ 指定成员 @ 文件或文件夹 ，还可以搜索文件名称或文件夹名称</el-col>-->
          <!--          <el-col>历史查看：文档所有协作者的编辑历史都被自动保存，可被追溯查看，误操作有据可查。</el-col>-->
          <!--          <el-col>版本保存和还原：用户可通过保存不同的版本，以保存某一时刻文档的全部内容</el-col>-->
          <!--          <el-col>多重权限管理：{{ productName }}提供多种账号和文件编辑权限设定</el-col>-->

          <!--          <el-col>版本: V1.0     交付方式: License</el-col>-->
          <!--          <el-col>适用于: Windows/Linux/Android/iOS     上架日期: 2021-11-19</el-col>-->
          <!--          <el-col>交付SLA: 15 自然日</el-col>-->

          <!--          <el-col> 1. 产品功能</el-col>-->
          <!--          <el-col>全站私有部署包含办公套件（文档、表格、表单、幻灯片、思维导图、白板）及工作台/我的桌面/快速访问/企业管理等空间。各组件具有多人协作编辑、历史查看、版本保存等通用特色功能，也具有各组件独特的功能，如文档中的划词评论、表格中的跨表格公式引用等；工作台/我的桌面/快速访问/企业管理等功能模块分别对应某个领域或业务的操作和管理。</el-col>-->
          <!--          <el-col>下面对{{ productName }}的特色功能展开介绍。</el-col>-->
          <!--          <el-col>1.1 特色功能</el-col>-->
          <!--          <el-col>多人协作编辑，毫秒级响应</el-col>-->
          <!--          <el-col>{{ productName }}允许多人实时编辑同一份文件，编辑过程多人实时可见，毫秒级同步响应。</el-col>-->
          <!--          <el-col>自动保存</el-col>-->
          <!--          <el-col>文件编辑内容实现实时自动保存。</el-col>-->
          <!--          <el-col>历史查看</el-col>-->
          <!--          <el-col>文档所有协作者的编辑历史都被自动保存，可被追溯查看，误操作有据可查。</el-col>-->
          <!--          <el-col>版本保存和还原</el-col>-->
          <!--          <el-col>用户可通过保存不同的版本，以保存某一时刻文档的全部内容，便于在进行多次修改后进行对比或者内容还原。文档支持保存任意多个版本，并可对每个版本单独命名。</el-col>-->
          <!--          <el-col>保存的版本通过还原操作可一键还原到选定的版本。</el-col>-->
          <!--          <el-col> 插入日期和时间</el-col>-->
          <!--          <el-col>{{ productName }}支持在文档中插入提醒日期和具体时间。编辑者通过日历面板选择需要提醒的日期和具体时间，若同时勾选“提醒我”，在时间到期后，会收到提醒通知。如果该时间提醒被删除或修改，编辑者也将收到通知。</el-col>-->
          <!--          <el-col>多重权限管理</el-col>-->
          <!--          <el-col>产品提供多种账号和文件编辑权限设定，且支持为每个协作者单独设置只读 、评论 、编辑权限，针对表格产品，提供单元格内权限可控功能。</el-col>-->
        </el-col>
      </el-col  >
      <el-col class="introduce-detail" :sm="0" :span="24">
        <el-col class="detail" :span="22">
          <el-col>
            <strong>商品亮点：</strong>
            轻松向客户、公司内部员工分享文件，
            轻松向客户、公司内部员工分享文件，
            区分个人文档和部门文档，受到系统特定的权限控制，高效地团队协作，
            记录用户操作日志，系统审计有迹可循，
          </el-col>
          <el-col>
            <strong>商品说明：</strong>
            本商品为SAAS标准产品，即买即用，不占用客户服务器资源。如需私有化部署或定制需求请联系我们，服务热线：400-830-0095。
          </el-col>
          <el-col><strong>商品介绍：</strong></el-col>
          <el-col>
            <ul>
              <li>伊登云文档管理系统是企业级的文档协同管理平台，专注为客户打造一个集中存储、安全、灵活高效的文档协同平台，对文档进行全生命周期管理与数据分析，实现内容创造价值，知识驱动未来。</li>
              <li>
                文档管理系统区分个人文档和部门文档，文档数据的上传、预览、删除、共享、分享、授权受到系统特定的权限控制，打破传统文档的管理方式，不受时间和空间的限制，形成高效地团队协作平台，操作动态数据可查，让企业内部协同办公更加安全放心。
              </li>
              <li>支持常用的Office文档（Word，Excel，PPT）、PDF、图片文件的预览及常见的视频和音频文件在线播放。</li>
              <li>用户个人文档下的文件或文件夹，通过共享方式授权给内部其他用户访问，授权方式包含新建、上传、下载、查看，既保证文件传输的高效快捷，又能保证文件的安全。</li>
              <li>高级搜索：系统支持快速搜索、标签搜索、标题搜索、文档内容搜索、创建人搜索等多种搜索形式，帮助用户快速查找定位资料。</li>
              <li>外接分享：用户可以对系统内的文件生成外链，系统外用户可以通过外链下载文件进行访问；可以对外链文件进行密码设置，访问次数设置，过期时间设置。</li>
              <li>文件标签：标签功能是一个灵活的文件分类方式，系统中所有对文件有编辑权限的用户都可以为文件添加标签，用户根据自己的需要为文件添加标签，方便用户分类查找与检索文件。</li>
              <li>文档比对：针对两份PDF文件，可进行内容在线对比，用颜色标识差异的内容，不同内容用颜色突出显示，一目了然。</li>
              <li>权限管理：6大模块细分55项权限设置。既支持对单个文件进行授权，也支持对单个用户/角色进行授权。</li>
              <li>流程管理：灵活自定义多种审批流程，系统支持外发分享审批。资料分享给外部用户需要系统内部审核机制通过后才会生成分享链接，确保资料安全。可按资料的机密等级，划定不同的审批权限。</li>
              <li>日志审计：系统可自动记录用户行为，并自动生成登录日志、组织操作日志、文档操作日志；安全管理员可通过审计日志监控系统使用情况，确保整体运营安全。</li>
              <li>回收站：针对用户误删除，或者恶意删除的文件，系统支持回收站管理，进行二次保护。对回收站内的文件，由文件上传人员进行处理，是否彻底删除或者恢复文件。</li>
            </ul>
          </el-col>
          <el-col><strong>服务时间：</strong>工作日9:00-18：00</el-col>
          <el-col><strong>服务热线：</strong>400-830-0095</el-col>
          <el-col><strong>售后服务内容：</strong>产品支持及使用问题跟踪</el-col>
          <el-col><strong>邮箱：</strong>EdenService@edensoft.com.cn</el-col>

          <!--          <el-col>提醒功能：@ 指定成员 @ 文件或文件夹 ，还可以搜索文件名称或文件夹名称</el-col>-->
          <!--          <el-col>历史查看：文档所有协作者的编辑历史都被自动保存，可被追溯查看，误操作有据可查。</el-col>-->
          <!--          <el-col>版本保存和还原：用户可通过保存不同的版本，以保存某一时刻文档的全部内容</el-col>-->
          <!--          <el-col>多重权限管理：{{ productName }}提供多种账号和文件编辑权限设定</el-col>-->

          <!--          <el-col>版本: V1.0     交付方式: License</el-col>-->
          <!--          <el-col>适用于: Windows/Linux/Android/iOS     上架日期: 2021-11-19</el-col>-->
          <!--          <el-col>交付SLA: 15 自然日</el-col>-->

          <!--          <el-col> 1. 产品功能</el-col>-->
          <!--          <el-col>全站私有部署包含办公套件（文档、表格、表单、幻灯片、思维导图、白板）及工作台/我的桌面/快速访问/企业管理等空间。各组件具有多人协作编辑、历史查看、版本保存等通用特色功能，也具有各组件独特的功能，如文档中的划词评论、表格中的跨表格公式引用等；工作台/我的桌面/快速访问/企业管理等功能模块分别对应某个领域或业务的操作和管理。</el-col>-->
          <!--          <el-col>下面对{{ productName }}的特色功能展开介绍。</el-col>-->
          <!--          <el-col>1.1 特色功能</el-col>-->
          <!--          <el-col>多人协作编辑，毫秒级响应</el-col>-->
          <!--          <el-col>{{ productName }}允许多人实时编辑同一份文件，编辑过程多人实时可见，毫秒级同步响应。</el-col>-->
          <!--          <el-col>自动保存</el-col>-->
          <!--          <el-col>文件编辑内容实现实时自动保存。</el-col>-->
          <!--          <el-col>历史查看</el-col>-->
          <!--          <el-col>文档所有协作者的编辑历史都被自动保存，可被追溯查看，误操作有据可查。</el-col>-->
          <!--          <el-col>版本保存和还原</el-col>-->
          <!--          <el-col>用户可通过保存不同的版本，以保存某一时刻文档的全部内容，便于在进行多次修改后进行对比或者内容还原。文档支持保存任意多个版本，并可对每个版本单独命名。</el-col>-->
          <!--          <el-col>保存的版本通过还原操作可一键还原到选定的版本。</el-col>-->
          <!--          <el-col> 插入日期和时间</el-col>-->
          <!--          <el-col>{{ productName }}支持在文档中插入提醒日期和具体时间。编辑者通过日历面板选择需要提醒的日期和具体时间，若同时勾选“提醒我”，在时间到期后，会收到提醒通知。如果该时间提醒被删除或修改，编辑者也将收到通知。</el-col>-->
          <!--          <el-col>多重权限管理</el-col>-->
          <!--          <el-col>产品提供多种账号和文件编辑权限设定，且支持为每个协作者单独设置只读 、评论 、编辑权限，针对表格产品，提供单元格内权限可控功能。</el-col>-->
        </el-col>
      </el-col >
    </el-col>
    <el-col class="product-domain">
      <el-dialog title="购买" :visible.sync="purchaseDialog" class="purchase-dialog" top="30vh"
                 :close-on-click-modal="false" @close="clearPropertyVal">
        <el-form :model="formValidate" :rules="ruleValidate" ref="domainForm">
          <el-form-item label="请输入您的个性化域名：" :label-width="formLabelWidth" prop="domain">
            <el-input v-model="formValidate.domain" placeholder="请输入自定义域名，如eden">
              <template slot="append">.edenyun.com</template>
            </el-input>
          </el-form-item>
        </el-form>
        <el-col class="form-btn">
          <el-button class="btn submit" @click="handleSubmit" :loading="submitLoading">确定</el-button>
          <el-button class="btn reset" @click="close()">取消</el-button>
        </el-col>
        <el-col class="purchase-tip">
          说明：比如你在域名输入框输入“eden”，后面你访问文档系统的地址为“eden.edenyun.com”。
        </el-col>
      </el-dialog>
    </el-col>
    <el-col class="product-pay">
      <el-dialog title="订单支付" :visible.sync="payDialog" class="pay-dialog" top="30vh" :close-on-click-modal="false"
                 @close="productPayClose">
        <el-col class="pay-type">
          <!--          <el-col class="pay-wechat active" :span="5">-->
          <!--            <i class="el-icon-wechat pay-icon"></i>-->
          <!--            <el-col :span="18" class="pay-title">微信扫码支付</el-col>-->
          <!--          </el-col>-->
          <el-col class="pay-ali " style="text-align: center">
              <i class="el-icon-ali pay-icon"></i><span class="active" style="color: black">支付宝扫码支付</span>
          </el-col>
        </el-col>
        <el-col class="qr-code" id="qrcode">
          <img :src="qrCodeImg"/>
        </el-col>

        <el-col class="price">
          <span class="symbol">￥</span>
          <span class="amount">{{ price }}</span>
        </el-col>
      </el-dialog>


      <el-dialog title="支付成功" :visible.sync="paySuccessDialog" class="pay-success-dialog" top="30vh"
                 :close-on-click-modal="false">
        <el-col class="price" style="color: green">
          <span>支付成功，即将跳转至订单详情，{{ secondNum }}s</span>
        </el-col>
        <el-col class="price">
          <el-button type="text" style="color: green;" @click="goOrderInfo()">立即跳转>></el-button>
        </el-col>
      </el-dialog>
    </el-col>
    <!--支付选择sku弹窗-->
    <el-col class="mobile-select-sku" :sm="0" :span="24"  >
      <el-drawer :span="24"
          title="我是标题"
          :visible.sync="drawer"
          :direction="direction"
          :with-header="false"
          size="75%">
        <el-col :span="5" :sm="0" class="mobile-product_img">
          <img src="../../img/goods/mobile-product.png" style="width: 100%;height: 100%">
        </el-col>
        <el-col :span="7">
        <el-col class="mobile-product-￥" :span="1" >
          ￥
        </el-col>
        <el-col class="mobile-product-price" :span="3" >
          {{ price }}
        </el-col>
        </el-col>
        <el-col :span="24">
          <el-col class="mobile-product-name" :span="24">规格名称：</el-col>
          <el-col  :span="24">
            <el-col :span="6" :title="item.productSpecsName" class="mobile-product-spec"
                    :class="{active: specsIndex == index}" v-for="(item, index) in productSpecsList" :key="index"
                    @click.native="chooseProductSpecs(index)">
              {{ item.productSpecsName }}
            </el-col>
            <el-col :span="20" v-if="ifOther" style="margin: 16px 0px 0px 16px;">
              <div class="other-spaces">
                <div style="margin-bottom: 10px;">
                  用户：
                  <el-input-number v-model="inputUserNum" :min="basicUser" :step="basicUser" step-strictly
                                   @change="calculateSpacesPrice"></el-input-number>
                </div>
                <div>
                  容量：
                  <el-input-number v-model="inputCapacity" :min="basicCapacity" :step="basicCapacity" step-strictly
                                   @change="calculateSpacesPrice"></el-input-number>
                  <el-select style="width: 60px;margin-left: 5px" v-model="capacityUnit" @change="inputUnitChange">
                    <el-option label="G" value="G">G</el-option>
                    <el-option label="T" value="T">T</el-option>
                  </el-select>
                </div>
              </div>
            </el-col>
          </el-col>
          <el-col class="mobile-buy-type" :xl="24">购买方式：</el-col>
          <el-col  :xl="24">
            <el-col :span="6" class="mobile-buy-type-spe" :class="{active: purchaseIndex == index}"
                    v-for="(item, index) in productPurchaseList" :key="index"
                    @click.native="choosePurchaseType(index)">
              <!--                按月购买-->
              <span v-if="item.billingMode == 1">按月购买</span>
              <span v-if="item.billingMode == 2">按年购买</span>
              <span v-if="item.billingMode == 3">试用</span>
            </el-col>
          </el-col>
          <el-col class="mobile-buy-type" :xs="12" :sm="8" :md="6" :lg="5" :xl="4">购买时长：</el-col>
          <el-col class="choose-duration" :xs="12" :sm="15" :md="12" :lg="10" :xl="6" style="margin: 16px 0px 0px 16px;">
            <el-input-number v-model="duration" :min="1" :max="durationMax"
                             @change="durationChange"></el-input-number>
            <el-col class="duration-type" :span="2">({{ unit }})</el-col>
          </el-col>
          <el-col class="mobile-buy-type" :span="24">请输入您的个性域名：</el-col>
          <el-col  :span="24" style="margin: 16px 0px 0px 16px;">
            <el-form :model="formValidate" :rules="ruleValidate" ref="domainForm">
              <el-input v-model="formValidate.domain" placeholder="请输入自定义域名，如eden" class="mobile-domain-name">
               <template class="mobile-append" slot="append">.edenyun.com</template>
              </el-input>
             </el-form>
            <el-col style="font-size: 8px;font-weight: 400;">说明：比如你在域名输入框输入“eden”，后面你访问文档系统的地址为“eden.edenyun.com”。</el-col>
          </el-col>
          <el-col style="background: #FFFFFF;  height: 52px; margin: 30px 0px 0px 16px" :span="24" >
            <el-col class="mobie-determine-count" :span="2">
              总计
            </el-col>
            <el-col class="mobie-determine-￥" :span="1">
              ￥
            </el-col>
            <el-col class="mobie-determine-price" :span="5">
              {{ price }}
            </el-col>
            <el-col style="margin: 9px -29px -9px 162px;" :span="1">
              <el-button class="mobie-determine-button" @click="handleSubmit1" >确认</el-button>
            </el-col>
          </el-col>
        </el-col>
      </el-drawer>
    </el-col>
  </el-row>
</template>

<script>

import QRCode from 'qrcodejs2'
import api from '@/api/payApi.js'
import goodsApi from '@/api/goodsApi.js'
import orderApi from '@/api/orderApi.js'

export default {
  name: "GoodsDescription",
  data() {
    return {
      mobile: false,
      direction: 'btt',
      drawer: false,
      productName: '文档安全管理系统',
      duration: 1,
      formValidate: {},
      formLabelWidth: '200px',
      specsIndex: 0,
      productSpecsList: [
        '50用户50G'
      ],
      purchaseIndex: 0,
      productPurchaseList: ['按月购买'],
      price: 10,
      initPrice: 10,
      ruleValidate: {
        domain: [
          {required: true, message: '请输入个性化域名', trigger: 'blur'},
          {pattern: /^(?! -)[A-Za-z0-9-]{1,20}$/, message: '域名格式不正确'}
        ]
      },
      orderNo: '',
      qrCodeImg: '',
      wsUrl: api.socketUrl,
      websocket: null,

      submitLoading: false,
      purchaseDialog: false,
      payDialog: false,
      goodsId: '792a32b7-f403-4d25-a040-0f76017e92c7',
      goodsInfo: {},
      inputUserNum: 50,
      inputCapacity: 50,
      capacityUnit: 'G',
      unit: '月',
      packageSpacesId: '',
      billingModeId: '',
      otherSpaces: {
        packageSpecsId: 'other'
        , productSpecsName: '自定义规格'
        , userNum: 50   //用户量
        , capacity: 50 //容量
        , billingModes: [
          {billingModeId: 'month', billingMode: 1, purchaseAmount: 0},
          {billingModeId: 'year', billingMode: 2, purchaseAmount: 0},
        ]
      },
      basicUser: 50,  //基础用户量，控制客户的输入
      basicCapacity: 50,   //基础容量
      //用户和容量的基数与价格，用于计算价格
      basic: {
        capacity: 0
        , capacityPrice: 0
        , user: 0
        , userPrice: 0
      }
      , ifOther: false //是否使用其他规格（自定义）
      , durationMax: 0
      , paySuccessDialog: false
      , secondNum: 5
    }
  },
  mounted() {
    this.getGoodsInfo();
  },
  destroyed() {
    this.websocketOnClose();
  },
  methods: {
    // 关闭移动端 支付选择sku组件
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(()=> {
            done();
          })
          .catch(() => {});
    },
    clearPropertyVal() {
      this.$refs.domainForm.resetFields();
    },
    getGoodsInfo() {
      let obj = this;
      goodsApi.getGoodDescription({saasProductId: obj.goodsId}).then(res => {
        if (res.data) {
          let data = res.data;
          obj.goodsInfo = data;

          let productPackage = data.productPackages[0];
          //获取容量和规格的基数与价格
          obj.basic.capacity = productPackage.capacityCardinality;
          obj.basic.capacityPrice = productPackage.capacityCardinalityPrice;
          obj.basic.user = productPackage.userNumCardinality;
          obj.basic.userPrice = productPackage.userNumCardinalityPrice;
          //商品规格
          let packageSpaces = productPackage.packageSpaces;
          obj.productSpecsList = packageSpaces;
          //计算自定义规格的计费模式的价格
          obj.calculateSpacesPrice();
          //添加自定义规格
          obj.productSpecsList.push(obj.otherSpaces);
          obj.packageSpacesId = packageSpaces[0].packageSpecsId;
          //购买方式
          obj.productPurchaseList = packageSpaces[0].billingModes;
          let billingMode = obj.productPurchaseList[0];
          if (billingMode.billingMode == 1) {
            obj.unit = '月'
            obj.durationMax = 11;
          } else if (billingMode.billingMode == 2) {
            obj.unit = '年'
            obj.durationMax = 5;
          } else {
            obj.durationMax = 29;
            obj.unit = '天';
          }
          this.billingModeId = billingMode.billingModeId;
          obj.initPrice = billingMode.purchaseAmount;
          obj.price = billingMode.purchaseAmount;
          obj.productName = obj.goodsInfo.saasProductName;
        }
      })
    },
    inputUnitChange() {
      if (this.capacityUnit == 'G') {
        this.basicCapacity = 50;
        this.inputCapacity = 50;
      } else {
        this.basicCapacity = 1;
        this.inputCapacity = 1;
      }
      this.calculateSpacesPrice();
    },
    //计算自定义规格的计费模式的价格
    calculateSpacesPrice() {
      //基数和基数价格就是，比如1用户5块钱，则1就是基数，5就是基数价格
      let otherSpaces = this.otherSpaces;
      otherSpaces.userNum = this.inputUserNum;
      if (this.capacityUnit == 'G') {
        //单位是G则不变
        otherSpaces.capacity = this.inputCapacity;
      } else {
        //单位是T就乘以1024
        otherSpaces.capacity = this.inputCapacity * 1024;
      }
      //包月的用户价格 = (自定义用户量 / 用户基数) * 基数价格
      let monthUserPrice = (otherSpaces.userNum / this.basic.user) * this.basic.userPrice;
      //包月的容量价格 = (自定义容量 / 容量基数) * 基数价格
      let monthCapacityPrice = (otherSpaces.capacity / this.basic.capacity) * this.basic.capacityPrice;

      //计算规格单价
      otherSpaces.billingModes.forEach(item => {
        if (item.billingMode == 1) {
          //包月
          item.purchaseAmount = monthUserPrice + monthCapacityPrice;
        } else {
          //包年
          item.purchaseAmount = (monthUserPrice + monthCapacityPrice) * 12;
        }
        //计算价格
        if (item.billingModeId == this.billingModeId) {
          this.initPrice = item.purchaseAmount;
          this.price = item.purchaseAmount * this.duration;
        }
      });

    },
    purchase() {
      let userInfo = this.$store.state.userInfo.userInfo;
      if (userInfo == "") {
        this.$router.push({path: '/login'});
        this.$message.error("请先登录");
        return;
      }

      this.purchaseDialog = true;
    },
    purchase1() {
      let userInfo = this.$store.state.userInfo.userInfo;
      if (userInfo == "") {
        this.$router.push({path: '/login'});
        this.$message.error("请先登录");
        return;
      }
    },
    handleSubmit() {
      // let userInfo = this.$store.state.userInfo.userInfo;
      // let obj = {
      //     'instanceDomain': this.formValidate.domain.toLowerCase(),
      //     'saasUserID': userInfo.saasUserId,
      //     'saasCompanyId': userInfo.saasCompanyId,
      //     'saasGoodsId': this.goodsId,
      //     'orderType': 1,
      //     'billingModeId': this.billingModeId,
      //     'saasGoodsPrice': this.price,
      //     'purchaseTime': this.duration,
      //     'packageSpacesId': this.packageSpacesId
      //     //使用自定义规格需要新加的参数
      //     ,'userNum': this.otherSpaces.userNum
      //     ,'capacity': this.otherSpaces.capacity
      //     ,'initPrice': this.initPrice
      //     ,'productPackageId': this.goodsInfo.productPackages[0].productPackageId
      // }
      // console.log('obj', obj);
      this.$refs.domainForm.validate((valid) => {
        console.log(valid)
        if (valid) {
          let obj = this;
          this.submitLoading = true;
          let userInfo = this.$store.state.userInfo.userInfo;
          let form = {
            'instanceDomain': this.formValidate.domain.toLowerCase(),
            'saasUserID': userInfo.saasUserId,
            'saasCompanyId': userInfo.saasCompanyId,
            'saasGoodsId': this.goodsId,
            'orderType': 1,
            'billingModeId': this.billingModeId,
            'saasGoodsPrice': this.price,
            'purchaseTime': this.duration,
            'packageSpacesId': this.packageSpacesId
            //使用自定义规格需要新加的参数
            // ,'userNum': this.otherSpaces.userNum
            // ,'capacity': this.otherSpaces.capacity
            // ,'initPrice': this.initPrice
            // ,'productPackageId': this.goodsInfo.productPackages[0].productPackageId
          };
          api.generatorOrder(form).then((res) => {
            obj.submitLoading = false;
            if (res.code != 200) {
              return obj.$message.error(res.message);
            }
            obj.mobile = 0;
            obj.orderNo = res.data;
            obj.initWebSocket();
              obj.getQrCodeUrl(this.orderNo);
            if (this.price > 0) {
              obj.payDialog = true;
            }
            obj.purchaseDialog = false;
          });
        }
      })
    },
    handleSubmit1() {
      this.purchase1();
      // let userInfo = this.$store.state.userInfo.userInfo;
      // let obj = {
      //     'instanceDomain': this.formValidate.domain.toLowerCase(),
      //     'saasUserID': userInfo.saasUserId,
      //     'saasCompanyId': userInfo.saasCompanyId,
      //     'saasGoodsId': this.goodsId,
      //     'orderType': 1,
      //     'billingModeId': this.billingModeId,
      //     'saasGoodsPrice': this.price,
      //     'purchaseTime': this.duration,
      //     'packageSpacesId': this.packageSpacesId
      //     //使用自定义规格需要新加的参数
      //     ,'userNum': this.otherSpaces.userNum
      //     ,'capacity': this.otherSpaces.capacity
      //     ,'initPrice': this.initPrice
      //     ,'productPackageId': this.goodsInfo.productPackages[0].productPackageId
      // }
      // console.log('obj', obj);
      this.$refs.domainForm.validate((valid) => {
        console.log(valid)
        if (valid) {
          let obj = this;
          this.submitLoading = true;
          let userInfo = this.$store.state.userInfo.userInfo;
          let form = {
            'instanceDomain': this.formValidate.domain.toLowerCase(),
            'saasUserID': userInfo.saasUserId,
            'saasCompanyId': userInfo.saasCompanyId,
            'saasGoodsId': this.goodsId,
            'orderType': 1,
            'billingModeId': this.billingModeId,
            'saasGoodsPrice': this.price,
            'purchaseTime': this.duration,
            'packageSpacesId': this.packageSpacesId
            //使用自定义规格需要新加的参数
            // ,'userNum': this.otherSpaces.userNum
            // ,'capacity': this.otherSpaces.capacity
            // ,'initPrice': this.initPrice
            // ,'productPackageId': this.goodsInfo.productPackages[0].productPackageId
          };
          api.generatorOrder(form).then((res) => {
            obj.submitLoading = false;
            if (res.code != 200) {
              return obj.$message.error(res.message);
            }
            obj.orderNo = res.data;
            obj.initWebSocket();
            obj.mobile = 1;
            obj.getQrCodeUrl(this.orderNo);
            // if (this.price > 0) {
            //   obj.payDialog = true;
            // }
          });
        }
      })
    },
    goOrderInfo() {
      let obj = this;
      orderApi.getOrderInfo({orderNo: obj.orderNo}).then(res => {
        if (res.code != 200) {
          return obj.$message.error(res.message);
        }
        let instanceId = res.data.instanceId;
        obj.$router.push({
          path: '/orderList',
          query: {
            instanceId: instanceId
          }
        })
      })
    },
    getQrCodeUrl(orderNo) {
      let obj = this;
      if (obj.price != 0) {
        if (obj.mobile == 1){
          //金额不为0，走支付
          api.getQrCodeUrl({"orderNo": orderNo, "type": "1"}).then((res) => {
            if (res.code != 200) {
              obj.$alert(res.message + "请在订单中心重新支付").then(() => {
                //如果获取失败，则获取订单详情，拿到实例id后，跳转到订单中心可重新支付
                obj.goOrderInfo();
              });
              // obj.$router.push('/membership')
              return;
            }
            window.location.href = res.data;
            obj.qrCodeImg = res.data;
          })
        }else {
          //金额不为0，走支付
          api.getQrCodeUrl({"orderNo": orderNo}).then((res) => {
            if (res.code != 200) {
              obj.$alert(res.message + "请在订单中心重新支付").then(() => {
                //如果获取失败，则获取订单详情，拿到实例id后，跳转到订单中心可重新支付
                obj.goOrderInfo();
              });
              // obj.$router.push('/membership')
              return;
            }
            obj.qrCodeImg = res.data;
          })
        }
      } else {
        let loading = obj.$loading({text: '处理中..'});
        // //金额为0，直接走开通
        api.openApp({
          mchOrderNo: orderNo,
          payOrderId: '',
          reqTime: parseInt(new Date().getTime() / 1000)
        }).then(res => {
          loading.close();
          if (res != 'success') {
            obj.$message.error('购买失败');
          }
          obj.$message.success('购买成功');
          obj.goOrderInfo();
        })
      }

    },
    //规格切换
    chooseProductSpecs(index) {
      this.packageSpacesId = this.productSpecsList[index].packageSpecsId;
      if (this.packageSpacesId == 'other') {
        //如果选择的其他规格，则展示自定义输入框
        this.ifOther = true;
      } else {
        this.ifOther = false;
      }
      this.productPurchaseList = this.productSpecsList[index].billingModes;
      let billingMode = this.productPurchaseList[0];
      if (billingMode.billingMode == 1) {
        this.unit = '月'
      } else if (billingMode.billingMode == 2) {
        this.unit = '年'
      } else {
        this.unit = '天';
      }
      this.billingModeId = billingMode.billingModeId;
      this.purchaseIndex = 0;
      this.initPrice = billingMode.purchaseAmount;
      this.price = billingMode.purchaseAmount;
      this.specsIndex = index;
      this.duration = 1;
    },
    //购买方式切换
    choosePurchaseType(index) {
      //购买方式id
      this.billingModeId = this.productPurchaseList[index].billingModeId;
      let billingMode = this.productPurchaseList[index]
      if (billingMode.billingMode == 1) {
        this.unit = '月'
        this.durationMax = 11;
      } else if (billingMode.billingMode == 2) {
        this.unit = '年'
        this.durationMax = 5;
      } else {
        this.durationMax = 29;
        this.unit = '天';
      }
      this.purchaseIndex = 0;
      this.initPrice = billingMode.purchaseAmount;
      this.price = billingMode.purchaseAmount;
      this.purchaseIndex = index;
      this.duration = 1;
    },
    generatorQrCode(url) {
      this.qrcode = new QRCode('qrcode', {
        text: url,
        width: 166,
        height: 166
      })
    },
    durationChange(currentVal) {
      this.price = currentVal * this.initPrice;
    },
    productPayClose() {
    },
    // 使用websocket建立前后端通讯
    initWebSocket() {
      if (typeof WebSocket === 'undefined') {
        return null;
      }
      let wsUrl = this.wsUrl + this.orderNo;
      wsUrl = wsUrl.replace("https", "wss").replace("http", "ws");
      this.websocket = new WebSocket(wsUrl);
      this.websocket.onmessage = this.websocketOnMessage
      // this.websocket.onopen = this.websocketOnOpen
      this.websocket.onerror = this.websocketOnError
      this.websocket.onclose = this.websocketOnClose
    },
    websocketOnMessage(res) {
      // 数据接收
      const resData = JSON.parse(res.data);
      if (resData === 200) {
        this.$message.success("支付成功");

        this.payDialog = false;
        this.paySuccessDialog = true;
        this.secondNum = 5;
        let paySuccess = setInterval(() => {
          if (this.secondNum-- == 0) {
            clearInterval(paySuccess);
            this.goOrderInfo();
          }
        }, 1000);

      }
    },
    websocketOnOpen() {
      // 连接建立之后执行send发送数据
      // let data = {'orderNo': this.orderNo};
      // console.log(data);
      // this.websocketSend(JSON.stringify(data));
    },
    websocketOnError() {
      // 连接建立失败后重试
      this.initWebSocket();
    },
    websocketOnClose(e) {
      // 断开连接
      console.log("断开连接", e);
    },
    websocketSend(data) {
      console.log(this.websocket);
      this.websocket.send(data);
    },
    close() {
      this.formValidate = {};
      this.purchaseDialog = false;
    }
  }
}
</script>

<style lang="less" scoped>
/*  .text-overflow{
    display: block;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
  }*/
@media only screen and (max-width: 767px) {
  ///deep/.el-input__inner {
  //  border-radius: 16px  0px  0px  16px  ;
  //}
  /deep/ .el-input-group__append {
    background-color: #9BB1EB;
    color: #ffffff;
    border: 1px solid #9BB1EB;
    border-radius: 0px  16px  16px  0px  ;

  }
  .goods-info {
    margin-top: 0 !important;
    .down-mobile {
      position:fixed;
      left:0px;
      bottom:0px;
      z-index:999;
    }
    .goods {
      margin: 0 !important;
      float: unset;
      height: 410px;
    }
    .introduce-detail {
      margin: 72px 0px 0px 16px !important;
      .detail {
        padding-left: 11px!important;
        padding-left: 0px !important;
      }
    }
  }

    .active{
        background: #3764D7 !important;
        color: #FFFFFF !important;
    }
}
.mobile-append {
  border-radius: 48px ;
}
.mobie-determine-button {
  border-radius: 36px  36px  36px  36px  ;
  background: #F56E65;
  color: #FFFFFF;
  height: 36px;
}
.mobie-determine-count {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  margin: 17px 0px 17px 0px;
}
.mobie-determine-price {
  height: 22px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin: 13px 0px 17px 4px;
}
.mobie-determine-￥ {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin: 18px 0px 16px 0px;
}
.mobile-buy-type-spe {
  border-radius: 14px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 31px;
  text-align: center;
  //padding-top: 4px;
  padding: 0px 8px;
  border: 1px solid #3764D7;
  color: #3764D7;
  margin: 12px 0px 0px 16px ;
}
.mobile-product-spec {
  border-radius: 14px;
  height: 32px;
  font-size: 14px;
  font-weight: 400;
  line-height: 31px;
  text-align: center;
  //padding-top: 4px;
  padding: 0px 8px;
  border: 1px solid #3764D7;
  color: #3764D7;
  margin: 12px 0px 0px 16px ;
}
.mobile-buy-type {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  margin: 18px 0px 0px 16px;;
}
.active{
    //background: #3764D7;
    color: #FFFFFF;
}

.mobile-product-name {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  margin: 30px 0px 0px 16px;
}
.mobile-product-price {
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin: 17px 0px 17px 4px;
}
.mobile-product-￥ {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin: 22px 5px 16px 0px;
}
.mobile-product_img {
  margin: 16px 16px 0px 16px;
}
.element.style {
  height: 80%;
}
.down-buy-button {
  border-radius: 36px  36px  36px  36px  ;
  background: #F56E65;
  color: #FFFFFF;
  height: 36px;
}
.down-buy-price {
  height: 22px;
  font-size: 20px;
  font-weight: bold;
  color: #333333;
  line-height: 22px;
  margin: 13px 0px 17px 4px;
}
.down-￥ {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 18px;
  margin: 18px 0px 16px 0px;
}
.down-count {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  line-height: 18px;
  margin: 17px 0px 17px 23px;
}
.mobile-title {
  font-size: 24px;
  font-weight: bold;
  color: #3764D7;
  line-height: 32px;
  margin: 28px 0px 0px 16px;
}

.buy-price {
  height: 22px;
  font-size: 20px !important;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 22px;
  margin: 16px 0px 18px 4px;
}

.￥ {
  height: 18px;
  font-size: 10px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 18px;
  margin: 22px 0px 16px 8px;
}

.buy-now {
  height: 18px;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 18px;
  margin: 19px 0px 0px 16px;
}

.card {
  background: black;
  font-size: 5px;
  width: 80px;
  height: 16px;
  color: white;
  text-align: center;
  margin: 24px 0px 24px 16px;
}

.productCard {
  width: 130px;
  height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin: 22px 0px 0px 5px;
}

.goods-info .goods-specs .specs .item-list .item:nth-child(3n + 1) {
  margin-left: 0;
}

.goods-back-bg {
  background: url("../../img/goods/goods_bg.png") no-repeat top center;
  width: 100%;
  height: 160px;

  .bg-content {
    margin: auto;
    float: unset;
    font-size: 40px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 56px;
    padding-top: 52px;
  }
}

.goods-info {
  margin-top: 64px;

  .goods {
    margin: auto;
    float: unset;
    height: 410px;
  }

  .goods-specs {
    .specs {
      margin-left: 48px;

      .product-name {
        font-size: 24px;
        font-weight: 600;
        color: #414141;
        line-height: 33px;
      }

      .item-title {
        font-size: 14px;
        color: #414141;
        line-height: 19px;
        padding-top: 6px;
      }

      .item-list {
        .item:first-child {
          margin-left: 0;
        }

        .item {
          border-radius: 4px;
          height: 32px;
          font-size: 14px;
          font-weight: 400;
          line-height: 32px;
          text-align: center;
          //padding-top: 4px;
          padding: 0px 8px;
          border: 1px solid #3764D7;
          color: #3764D7;
          margin-left: 16px;
          margin-bottom: 10px;
        }

        .item:hover {
          cursor: pointer;
        }

        .active {
          background: #3764D7;
          color: #FFFFFF;
        }
      }

      .specs-name {
        margin-top: 38px;
      }

      .product-item {
        //height: 32px;
      }

      .pay-type {
        margin-top: 10px;
      }

      .duration {
        margin-top: 30px;

        .choose-duration {
          font-size: 14px;
          color: #414141;
          line-height: 20px;

          .duration-type {
            float: unset;
            display: inline-block;
            margin-left: 8px;
          }
        }
      }

      .price {
        margin-top: 24px;

        .item-price {
          .cur-price {
            font-size: 24px;
            font-weight: 400;
            color: #000000;
            line-height: 33px;
          }

          .aft-price {
            font-size: 14px;
            font-weight: 400;
            color: #E30000;
            line-height: 20px;
            padding-top: 7px;
          }
        }
      }

      .pay-btn {
        margin-top: 14px;

        .el-button {
          width: 208px;
          height: 34px;
          background: #F56E65;
          border-radius: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 20px;
          padding: 7px 20px;
        }

      }
    }
  }

  .dividing-line {
    margin: auto;
    float: unset;
    height: 1px;
    opacity: 0.4;
    border: 1px solid #979797;
  }

  .top {
    margin-top: 65px;
  }

  .bottom {
    margin-top: 11px;
  }

  .product-introduce {
    margin: auto;
    float: unset;
    height: 32px;
    padding-top: 11px;
    margin-bottom: 18px;

    .line {
      height: 32px;
      background: #3764D7;
      border-radius: 3px;
      width: 5px;
      margin-left: 11px;
    }

    .title {
      font-size: 16px;
      font-weight: 500;
      color: #3764D7;
      line-height: 22px;
      margin-left: 16px;
      margin-top: 5px;
    }
  }

  .introduce-detail {
    margin: auto;
    float: unset;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
    line-height: 20px;

    .detail {
      margin-top: 16px;
      margin-bottom: 68px;
      padding-left: 11px;
      line-height: 30px;
    }
  }
}

.purchase-dialog {

  .form-btn {
    text-align: center;
    margin-top: 30px;

    .btn {
      width: 80px;
      height: 32px;
      border-radius: 4px;
      padding: 6px 20px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .submit {
      background: #3764D7;
      color: #FFFFFF;
    }

  }

  .purchase-tip {
    font-size: 12px;
    font-weight: 400;
    color: #979797;
    line-height: 17px;
    text-align: center;
    margin-top: 24px;
  }

  /deep/ .el-dialog {
    height: 238px;
  }
}

.product-pay {

  .qr-code {
    margin-top: 23px;
    //margin-left: 370px;
      text-align: center;

    img {
      width: 160px;
      height: 160px;
    }
  }

  .price {
    text-align: center;
    margin-top: 16px;
    color: #E30000;
    line-height: 20px;

    .symbol {
      font-size: 14px;
      font-weight: 200;
    }

    .amount {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .pay-success-dialog {
    /deep/ .el-dialog__body {
      padding: 0 20px 30px 20px;
      height: 80px !important;
    }
  }

  .pay-dialog {

    /deep/ .el-dialog__body {
      padding: 0 20px 30px 20px;
      height: 309px !important;
    }

    .pay-type {
      height: 50px;
      border-top: 1px solid #cbcbcb;
      padding: 16px 0 16px 0px;
      border-bottom: 1px solid #cbcbcb;

      .active {
        border-bottom: 3px solid #3764D7;
        padding-bottom: 8px;
      }

      .pay-ali {
        //margin-left: 48px;
          display: flex;
          justify-content: center;
      }

      .pay-wechat:hover, .pay-ali:hover {
        cursor: pointer;
      }

      .pay-wechat, .pay-ali {
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        vertical-align: top;

        .el-icon-wechat {
          background: url("../../img/goods/weixinzhifu.svg") no-repeat top center;
          width: 22px;
          height: 19px;

        }

        .el-icon-ali {
          background: url("../../img/goods/zhifubao.svg") no-repeat top center;
          width: 20px;
          height: 20px;
        }

        .pay-icon {
          float: unset;
          display: inline-block;
        }

        .pay-title {
          float: unset;
          display: inline-block;
          vertical-align: top;
          margin-left: 9px;
        }
      }
    }
  }
}

/deep/ .el-input-number {
  width: 112px;
}

/deep/ .el-input-number__decrease, /deep/ .el-input-number__increase {
  width: 35px;
  top: 2px;
  height: 33px;
}

/deep/ .el-dialog__title {
  font-size: 18px;
  font-weight: 600;
  color: #3764D7;
  line-height: 22px;
}

/deep/ .el-dialog__header {
  padding: 16px 17px;
}

/deep/ .el-input-group {
  width: 400px;
}

.other-spaces {
  font-size: 14px;
}

/deep/ .el-input-number .el-input__inner {
  padding: 0px;
}

/deep/ .el-input-number__decrease {
  width: 30px;
}


</style>
